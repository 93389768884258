import { ShieldCheckIcon, CursorArrowRaysIcon , ScaleIcon   } from '@heroicons/react/20/solid';
import { BiZoomOut } from 'react-icons/bi';
import ContactForm from '../contactform/contactform.component';

export default function ImmTesto({ firstcontent }) {
    
    return (
      <section id="immtesto" className="bg-white p-0  ">

      <>        <div className="w-full flex justify-center mb-8">
    <img src="images/logo_moretti.svg"   alt="Logo Moretti" width="348" height="212" className="h-32 mt-10" />
  </div>
      <div className="text-right container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 px-4">

      {/* Colonna 1 */}
      <div className="bg-white p-8   rounded-lg">
         
        <p className="text-gray-700">
          <img   data-aos-duration={1000} // Ogni immagine ha una durata crescente
          data-aos="fade-up"
           src="images/01.webp" alt="Immagine" className="mb-4" />
        </p>
      </div>
  
      {/* Colonna 2 */}
      <div data-aos-duration={1000} data-aos="zoom-out" className="bg-white p-8   rounded-lg  ">
      <h1 className='lg:text-7xl text-5xl md:text-titolo leading-1 mb-5 md:mt-4 mt-0	text-rossomoretti font-black'>{firstcontent[0].red}</h1>
      <h3 className='text-titolino leading-titolino	 text-black font-black'>{firstcontent[0].prodotto}</h3>
      <p className="text-black mt-5">
        Prezzo speciale:      </p>
        <p className="text-4xl font-black my-3 ">
          {firstcontent[0].prezzo}
        </p>
        <p className="text-black mb-5" dangerouslySetInnerHTML={{ __html: firstcontent[0].testosotto }}></p>
 
          <ContactForm
            title="Contattaci ora!"
            description="Compila il modulo e ti contatteremo il prima possibile."
            whatsappLink="https://wa.me/393442741976"
            dimensionifont=" text-2xl md:text-3xl"
          />
   

    <p className="text-black mt-4">
    <span className='font-black'>ATTENZIONE:</span><br />
Offerta valida solo fino ad esaurimento scorte!
</p>
      </div>
    </div></>
    </section>
    
        );
    }


   