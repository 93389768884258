import React from 'react';
import  ContactForm from '../contactform/contactform.component';

export default function CtaTesto({ ctatesti }) {
  return (
    <section id="ctatesto" className="md:p-10 p-6">
      <div className="bg-white">
        <div className="mx-auto w-full px-4 pt-18 pb-8 sm:px-6 lg:px-8">
          <div className="rounded-2xl px-6 pt-16 pb-2 sm:p-2">
            <div className="mx-auto max-w-xl lg:max-w-none">
              <div className="text-center">
                <h2
                  data-aos-duration={500}
                  data-aos="fade-up"
                  data-aos-delay={500}
                  style={{ marginBottom: '0px' }}
                  className="md:text-6xl text-4xl font-black tracking-tight text-gray-900"
                >
                  {ctatesti[0].row1}
                </h2>
                <h2
                  data-aos-duration={500}
                  data-aos="fade-up"
                  data-aos-delay={500}
                   
                  className="text-[50px] md:text-[90px] leading-10 md:leading-relaxed md:mt-0 mt-2 font-black tracking-tight text-giallomoretti"
                >
                  {ctatesti[0].row2}
                </h2>
              </div>
            </div>
          </div>
     
   {/*        <p
            data-aos-duration={500}
            data-aos="fade-up"
            data-aos-delay={700}
            className="text-5xl leading-tight mb-16"
            dangerouslySetInnerHTML={{ __html: ctatesti[0].row3 }}
          ></p> */}
     
      <div className="bg-white">
        <div className="text-center mx-auto items-center max-w-8xl px-4 mb-28 sm:px-6 lg:px-8">
          <p
            className="text-5xl leading-tight mb-16"
          >
           Compila il modulo e ti contatteremo il prima possibile.
          </p>
         <ContactForm
            title="Contattaci ora!"
            description="Compila il modulo e ti contatteremo il prima possibile."
            whatsappLink="https://wa.me/393442741976"
            dimensionifont=" text-2xl md:text-5xl"
          />
        </div>
      </div>
  

          <div className="mt-4 max-w-5xl text-center mx-auto items-center max-w-8xl px-4 mb-40 sm:px-6 lg:px-8">
            <p
              data-aos-duration={500}
              data-aos="fade-up"
              data-aos-delay={500}
              className="md:text-5xl text-3xl leading-tight mb-32"
              dangerouslySetInnerHTML={{ __html: ctatesti[0].row4 }}
            ></p>
          </div>
        </div>
      </div>
    </section>
  );
}
